<template>
	<div>
		<v-btn
			:color="applyButtonColor"
			:loading="(actionStarted && !actionFinished) || loadingParent"
			:outlined="offer.applied || (actionStarted && !actionFinished)"
			:disabled="offer.applied || actionStarted || !authenticated || offer.status.closed.value"
			@click="openDialog()"
			rounded
		>
			<span>{{ applyButtonText }}</span>
		</v-btn>
		<v-dialog v-model="dialog" persistent max-width="350">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('courses.areYouSure') }}</span>
				</v-card-title>
				<v-card-text class="text-justify">
					<span>{{ $t('offers.applyConfirmationInternal') }}</span>
					<br />
					<br />
					<template v-if="hasTemplates">
						<p class="text-body-1">
							{{ $t('documents.linkDocumentsHelp') }}
						</p>
						<v-form ref="form">
							<v-autocomplete
								v-model="template"
								:label="$t('documents.template')"
								:placeholder="$t('studies.select')"
								:items="templates"
								item-text="name"
								item-value="id"
								return-object
								outlined
								clearable
							/>
						</v-form>
						<br />
						<br />
					</template>
					<template v-else>
						<p class="text-body-1" style="color: red">
							{{ $t('documents.noTemplateToLink1') }}
						</p>
						<p class="text-body-1">
							{{ $t('documents.noTemplateToLink2') }}
						</p>
						<v-btn color="warning" :to="{ name: 'SettingsDocuments' }">
							{{ $t('documents.goToDocuments') }}
						</v-btn>
					</template>
					<span v-if="offer.category.external || offer.category.justExternal" class="text-h6 font-weight-bold deep-orange--text">
						{{ $t('offers.proceedExternalApplication') }}
					</span>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="error darken-1" text @click="closeDialog()">
						{{ $t('offers.cancel') }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="!!template ? callApplyOffer() : openDialogSecond()">
						{{ $t('applications.applyButton') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogSecond" persistent max-width="350">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('courses.areYouSure') }}</span>
				</v-card-title>
				<v-card-text class="text-justify">
					<span>{{ $t('offers.noTemplateSelected') }}</span>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="error darken-1" text @click="closeDialogSecond()">
						{{ $t('offers.cancel') }}
					</v-btn>
					<v-btn color="blue darken-1" text @click="callApplyOffer()">
						{{ $t('applications.applyButton') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferApplyButton',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			dialogSecond: false,
			applyButtonText: '',
			actionStarted: false,
			actionFinished: false,
			actionSuccess: false,
			template: null,
		}
	},
	watch: {
		offer() {
			if (this.offer.applied) {
				this.applyButtonText = this.$t('applications.alreadyApplied')
			} else {
				this.applyButtonText = this.$t('applications.applyButton')
			}
		},
		authenticated() {
			if (this.authenticated) this.fetchDocumentsTemplates()
		},
	},
	computed: {
		applyButtonColor() {
			if (this.actionFinished && this.actionSuccess) {
				return 'success'
			} else if (this.actionFinished && !this.actionSuccess) {
				return 'warning'
			} else {
				return 'primary'
			}
		},
		hasTemplates() {
			return this.templates ? !!this.templates.length : false
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			offer: 'offers/offer',
			templates: 'documents/templates',
		}),
	},
	created() {
		if (this.offer.applied) {
			this.applyButtonText = this.$t('applications.alreadyApplied')
		} else {
			this.applyButtonText = this.$t('applications.applyButton')
		}
		if (this.authenticated) this.fetchDocumentsTemplates()
	},
	methods: {
		openDialog() {
			this.dialogSecond = false
			this.dialog = true
		},
		closeDialog() {
			this.dialog = false
		},
		openDialogSecond() {
			this.dialog = false
			this.dialogSecond = true
		},
		closeDialogSecond() {
			this.dialogSecond = false
			this.dialog = true
		},
		callApplyOffer() {
			if (!this.actionStarted || !this.offer.applied) {
				this.actionStarted = true
				this.applyButtonText = this.$t('search.loading')
				this.applyOffer({
					offer: this.offer,
					templateID: this.template ? this.template.id : 0,
				})
					.then((data) => {
						this.actionSuccess = data.success
						this.applyButtonText = this.$t('settings.done')
					})
					.catch(() => {
						this.actionSuccess = false
						this.applyButtonText = this.$t('search.error')
					})
					.then(() => {
						this.actionFinished = true
					})
				if (this.offer.category.external || this.offer.category.justExternal) {
					window.open(this.offer.externalLink, '_blank')
					this.applyOfferExternal(this.offer.id)
				}
			}
			this.dialog = false
			this.dialogSecond = false
		},
		...mapActions('offers', ['applyOffer', 'applyOfferExternal']),
		...mapActions('documents', ['fetchDocumentsTemplates']),
	},
}
</script>
